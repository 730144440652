
import { ref, defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String
    },
    hint: String,
    disabled: Boolean,
    modelValue: {
      type: [String, Number]
    },
    inputViewType: {
      type: String,
      default: 'default'
    },
    required: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String
    }
  },
  setup(props) {
    const inputValue = props.modelValue ? ref(props.modelValue) : null
    return {
      inputValue
    }
  }
})
