const getRequiredDocuments = (data: any, docType1: string, docType2: string, docType3: string | null) => {
  if (data && data.documents.length <= 0) {
    if (docType3 !== null) {
      data.documents.push(
        { type: docType1, documentId: null },
        { type: docType2, documentId: null },
        { type: docType3, documentId: null }
      )
    } else {
      data.documents.push({ type: docType1, documentId: null }, { type: docType2, documentId: null })
    }
  } else {
    data.requiredDocs.sort(function (x: any, y: any) {
      return x.value === y.value ? 0 : x.value ? 1 : -1
    })
    let NDAReqDoc = ''
    let MSAReqDoc = ''
    let SOWReqDoc = ''
    data.requiredDocs.map((reqDoc: any) => {
      if (reqDoc.key === docType1 && reqDoc.value === false) {
        NDAReqDoc = 'false'
      } else if (reqDoc.key === docType1 && reqDoc.value === true) {
        NDAReqDoc = 'true'
      }
      if (reqDoc.key === docType2 && reqDoc.value === false) {
        MSAReqDoc = 'false'
      } else if (reqDoc.key === docType2 && reqDoc.value === true) {
        MSAReqDoc = 'true'
      }
      if (docType3 !== null) {
        if (reqDoc.key === docType3 && reqDoc.value === false) {
          SOWReqDoc = 'false'
        } else if (reqDoc.key === docType3 && reqDoc.value === true) {
          SOWReqDoc = 'true'
        }
      }
    })
    if (NDAReqDoc === 'false') {
      data.documents.push({ type: docType1, documentId: null })
    }
    if (MSAReqDoc === 'false') {
      data.documents.push({ type: docType2, documentId: null })
    }
    if (docType3 !== null) {
      if (SOWReqDoc === 'false') {
        data.documents.push({ type: docType3, documentId: null })
      }
    }
  }
  return data
}

const engFormatValue = (percentage: number): string => Math.round(percentage).toLocaleString()

export default {
  getRequiredDocuments,
  engFormatValue
}
export const decimalRound = (amount: number): number => Math.round(amount * 100) / 100

const decimalValuePattern = /^[0-9]+$/

export const decimalValueTest = (value: string) => decimalValuePattern.test(value.toString())
