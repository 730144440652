<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form :initial-values="initialValues" :validation-schema="schema" @submit="submit">
      <FormWrapper>
        <template #form-head>
          <h2>Edit studio details</h2>
        </template>
        <TextInput name="name" testId="studioName" type="text" placeholderText="Name" label="Studio name" />
        <SelectInput
          label="How many employees do you have?"
          selectLabel="Select number of employees"
          name="size"
          :items="companySizeValues"
          testId="studioSize"
        ></SelectInput>
        <TextInput
          name="legalId"
          type="text"
          placeholderText="Company number"
          label="Company number"
          tooltipTitle="Company number"
          tooltipText="A company registration number is a unique combination of numbers and, in some cases, letters. The company number is used to identify your company and verify the fact that it is a legally registered entity. You can find your (or a client’s or subcontractor’s) company number at your country’s registrar of companies."
          testId="studioLegalId"
        />
        <Geolocation
          name="address"
          type="text"
          placeholderText="Registered address"
          label="Your studio address"
          id="address"
          inputType="Edit"
          testId="studioAddress"
        />

        <Geolocation
          name="mailAddress"
          type="text"
          placeholderText="Mailing address"
          label="Your mailing address"
          id="mailAddress"
          inputType="Edit"
          testId="studioMailAddress"
        />
        <TextInput
          type="number"
          name="standardWorkHours"
          placeholderText="Work hours per day"
          label="Standard work hours per day"
          testId="standardWorkHours"
        />
        <TextInput
          type="number"
          name="workingDaysPerYear"
          placeholderText="Working days per year"
          label="Working days per year"
          testId="workingDaysPerYear"
        />
        <span>What are the main services you offer?</span>
        <div v-for="(studioService, index) of studioServiceArray" :key="studioService.id" class="loop-wrapper">
          <SelectInputModal
            v-model="studioService.name"
            testId="services"
            selectLabel="Select services"
            :items="StudioServicesList"
          />
          <a class="cross-button" data-testid="removeService" @click="() => removeStudioService(index)">X</a>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addStudioService"
          iconClass="plus"
          label="Add service"
          color="grey"
          size="small"
          testId="addService"
        />
        <h3>Financial details</h3>
        <TextInput
          name="accountNumber"
          type="text"
          placeholderText="A/C no."
          label="Your account number?"
          testId="accountNumber"
        />
        <TextInput name="bankName" type="text" placeholderText="Bank name" testId="bankName" />
        <TextInput name="bankAddress" type="text" placeholderText="Bank address" testId="bankAddress" />
        <TextInput name="sortCode" type="text" placeholderText="Sort code / BSB number" testId="sortCode" />
        <TextInput name="bic" type="text" placeholderText="BIC (optional)" testId="bic" />
        <TextInput name="iban" type="text" placeholderText="IBAN (optional)" testId="iban" />
        <TextInput name="vatNumber" type="text" placeholderText="VAT number (if applicable)" testId="vatNumber" />
        <TextInput name="VatPercentage" type="number" placeholderText="VAT / GST percentage" testId="VatPercentage" />
        <template #footer>
          <CustomButton
            color="grey"
            :iconVisible="false"
            @onClick="$router.go(-1)"
            label="Cancel"
            testId="cancelEdit"
          />
          <CustomButton type="submit" label="Update details" color="blue" testId="updateDetails" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import SelectInputModal from '@/components/atom/SelectInputModal.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import authApi from '@/api/Authenticated'
import { studioTypes } from '@shared/'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import Geolocation from '@/components/atom/Geolocation.vue'
import { decimalValueTest } from '@/utils/commonFunctions'

export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header,
    SelectInput,
    Geolocation,
    SelectInputModal
  },
  async setup() {
    const router = useRouter()
    const currentValues = await authApi.getStudioDetails()
    const initialValues = ref<studioTypes.StudioWithDetails>(currentValues)

    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const StudioServicesList = [
      {
        label: 'Advertising',
        value: 'Advertising'
      },
      {
        label: 'Brand strategy',
        value: 'Brand_strategy'
      },
      {
        label: 'Business_strategy',
        value: 'Business_strategy'
      },
      {
        label: 'Content strategy & production',
        value: 'Content_strategy_and_Production'
      },
      {
        label: 'Creative services',
        value: 'Creative_services'
      },
      {
        label: 'Customer experience',
        value: 'Customer_experience'
      },
      {
        label: 'Digital product development',
        value: 'Digital_product_development'
      },
      {
        label: 'ECommerce',
        value: 'ECommerce'
      },
      {
        label: 'Engineering',
        value: 'Engineering'
      },
      {
        label: 'Experience marketing',
        value: 'Experience_marketing'
      },
      {
        label: 'Full service agency',
        value: 'Full_service_agency'
      },
      {
        label: 'Graphic design',
        value: 'Graphic_design'
      },
      {
        label: 'Insight and market research',
        value: 'Insight_and_market_research'
      },
      {
        label: 'Partnership marketing',
        value: 'Partnership_marketing'
      },
      {
        label: 'Product design',
        value: 'Product_design'
      },
      {
        label: 'PR and communication',
        value: 'PR_and_communication'
      },
      {
        label: 'Service design',
        value: 'Service_design'
      },
      {
        label: 'Search marketing',
        value: 'Search_marketing'
      },
      {
        label: 'Social media',
        value: 'Social_media'
      },
      {
        label: 'Technology consulting',
        value: 'Technology_consulting'
      },
      {
        label: 'User experience',
        value: 'User_experience'
      }
    ]

    const schema = yup.object().shape({
      name: yup.string().required('Studio name is required').label('Studio name'),
      address: yup.string().required('Studio address is required').label('Studio address'),
      studioServices: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required().label('Name')
          })
        )
        .strict(),
      accountNumber: yup.string().required().label('Account number'),
      bankName: yup.string().required().label('Bank name'),
      bankAddress: yup.string().required().label('Bank address'),
      sortCode: yup.string().required().label('Sort code'),
      VatPercentage: yup
        .number()
        .test('is-decimal', 'VAT / GST should be only whole number', (val) => {
          if (val !== undefined) {
            return decimalValueTest(val.toString())
          }
          return true
        })
        .required()
        .label('Vat percentage')
        .typeError('VAT / GST percentage is required and must be number'),
      standardWorkHours: yup
        .number()
        .typeError('Standard work hours per day must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required(),
      workingDaysPerYear: yup
        .number()
        .typeError('Working days per year must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required(),
      legalId: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Only alphabets and numbers are allowed')
        .required('Company number is required')
    })
    const studioServiceArray = ref([...initialValues.value.studioServices])
    const addStudioService = () => {
      studioServiceArray.value.push({ name: '' })
    }
    const removeStudioService = (index: number) => {
      const filteredArr = studioServiceArray.value.filter((element, i: number) => {
        return i !== index
      })
      studioServiceArray.value = [...filteredArr]
    }
    async function submit(values: studioTypes.StudioPutBody) {
      values.studioServices = studioServiceArray.value
      values.vatNumber = values.vatNumber || null
      values.VatPercentage = Number(values.VatPercentage)
      values.standardWorkHours = Number(values.standardWorkHours)
      values.workingDaysPerYear = Number(values.workingDaysPerYear)
      const res = await authApi.updateStudio(values)
      if (res) {
        router.push({ name: 'Studio' })
      }
    }
    return {
      initialValues,
      submit,
      schema,
      companySizeValues,
      StudioServicesList,
      studioServiceArray,
      addStudioService,
      removeStudioService
    }
  }
})
</script>

<style lang="sass" scoped>
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
.loop-wrapper
  position: relative
small
  font-size: 12px
.additional-info > *
  margin: 1em 0
.cross-button
  width: 2rem
  height: 2rem
  border: 1px solid $white
  display: block
  position: absolute
  right: -40px
  top: 10px
  border-radius: 100%
  text-align: center
  background-color: $white
  cursor: pointer
  box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  display: flex
  align-items: center
  justify-content: center
.hidden-field
  display: none
</style>
