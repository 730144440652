<template>
  <div class="form form-maxer">
    <div class="form-head">
      <slot name="form-head" />
    </div>
    <span class="inner">
      <slot />
    </span>
    <span class="submit">
      <slot name="form-submit" />
    </span>
    <div v-if="hasFooterSlot" class="footer-submit">
      <div class="footer-row">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  setup(props, context) {
    const hasFooterSlot = computed(() => {
      return context.slots.footer
    })
    return { hasFooterSlot }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
$bottom-padding: 5rem
$max-width-form: 22.5rem
.form
  width: 100%
  max-width: $max-width-form
  padding-bottom: 2rem
  position: relative
  margin: 0 auto
  padding-bottom: $bottom-padding
  &.form-maxer
    max-width: 420px
  @media (max-width: $md-breakpoint)
    padding: 0 1.5rem
  .form-head
    padding-bottom: 1rem
    h2
      margin-bottom: 1rem
      line-height: 1.2
    p
      margin-bottom: 1rem
  .inner > *
    margin: 1em 0

  .submit
    display: block
    margin-top: 2rem
    button:not(:first-child)
      margin-top: 2rem
.footer-submit
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  z-index: 999
  bottom: 0
  left: 0
  background-color: $white
  // padding: 1rem 0
  .footer-row
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    max-width: $max-width-form
    margin: 1rem
    @media( max-width: $md-breakpoint)
      margin-left: 0
    button
      &:first-child
        max-width: 6rem
        padding: 0.5rem
        span
          margin: 0 auto
    & > *
      margin: 0 0.5rem
      max-width: 15rem
</style>
