
import { defineComponent, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import SelectInputModal from '@/components/atom/SelectInputModal.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import authApi from '@/api/Authenticated'
import { studioTypes } from '@shared/'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import Geolocation from '@/components/atom/Geolocation.vue'
import { decimalValueTest } from '@/utils/commonFunctions'

export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header,
    SelectInput,
    Geolocation,
    SelectInputModal
  },
  async setup() {
    const router = useRouter()
    const currentValues = await authApi.getStudioDetails()
    const initialValues = ref<studioTypes.StudioWithDetails>(currentValues)

    const companySizeValues = [
      {
        label: '1 - 5',
        value: 'S1_5'
      },
      {
        label: '6 - 25',
        value: 'S6_25'
      },
      {
        label: '26 - 100',
        value: 'S26_100'
      },
      {
        label: '101+',
        value: 'S101_PLUS'
      }
    ]

    const StudioServicesList = [
      {
        label: 'Advertising',
        value: 'Advertising'
      },
      {
        label: 'Brand strategy',
        value: 'Brand_strategy'
      },
      {
        label: 'Business_strategy',
        value: 'Business_strategy'
      },
      {
        label: 'Content strategy & production',
        value: 'Content_strategy_and_Production'
      },
      {
        label: 'Creative services',
        value: 'Creative_services'
      },
      {
        label: 'Customer experience',
        value: 'Customer_experience'
      },
      {
        label: 'Digital product development',
        value: 'Digital_product_development'
      },
      {
        label: 'ECommerce',
        value: 'ECommerce'
      },
      {
        label: 'Engineering',
        value: 'Engineering'
      },
      {
        label: 'Experience marketing',
        value: 'Experience_marketing'
      },
      {
        label: 'Full service agency',
        value: 'Full_service_agency'
      },
      {
        label: 'Graphic design',
        value: 'Graphic_design'
      },
      {
        label: 'Insight and market research',
        value: 'Insight_and_market_research'
      },
      {
        label: 'Partnership marketing',
        value: 'Partnership_marketing'
      },
      {
        label: 'Product design',
        value: 'Product_design'
      },
      {
        label: 'PR and communication',
        value: 'PR_and_communication'
      },
      {
        label: 'Service design',
        value: 'Service_design'
      },
      {
        label: 'Search marketing',
        value: 'Search_marketing'
      },
      {
        label: 'Social media',
        value: 'Social_media'
      },
      {
        label: 'Technology consulting',
        value: 'Technology_consulting'
      },
      {
        label: 'User experience',
        value: 'User_experience'
      }
    ]

    const schema = yup.object().shape({
      name: yup.string().required('Studio name is required').label('Studio name'),
      address: yup.string().required('Studio address is required').label('Studio address'),
      studioServices: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required().label('Name')
          })
        )
        .strict(),
      accountNumber: yup.string().required().label('Account number'),
      bankName: yup.string().required().label('Bank name'),
      bankAddress: yup.string().required().label('Bank address'),
      sortCode: yup.string().required().label('Sort code'),
      VatPercentage: yup
        .number()
        .test('is-decimal', 'VAT / GST should be only whole number', (val) => {
          if (val !== undefined) {
            return decimalValueTest(val.toString())
          }
          return true
        })
        .required()
        .label('Vat percentage')
        .typeError('VAT / GST percentage is required and must be number'),
      standardWorkHours: yup
        .number()
        .typeError('Standard work hours per day must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required(),
      workingDaysPerYear: yup
        .number()
        .typeError('Working days per year must be written as a whole number 8. Or as a decimal 7.5')
        .min(0)
        .required(),
      legalId: yup
        .string()
        .matches(/^[a-z0-9]+$/i, 'Only alphabets and numbers are allowed')
        .required('Company number is required')
    })
    const studioServiceArray = ref([...initialValues.value.studioServices])
    const addStudioService = () => {
      studioServiceArray.value.push({ name: '' })
    }
    const removeStudioService = (index: number) => {
      const filteredArr = studioServiceArray.value.filter((element, i: number) => {
        return i !== index
      })
      studioServiceArray.value = [...filteredArr]
    }
    async function submit(values: studioTypes.StudioPutBody) {
      values.studioServices = studioServiceArray.value
      values.vatNumber = values.vatNumber || null
      values.VatPercentage = Number(values.VatPercentage)
      values.standardWorkHours = Number(values.standardWorkHours)
      values.workingDaysPerYear = Number(values.workingDaysPerYear)
      const res = await authApi.updateStudio(values)
      if (res) {
        router.push({ name: 'Studio' })
      }
    }
    return {
      initialValues,
      submit,
      schema,
      companySizeValues,
      StudioServicesList,
      studioServiceArray,
      addStudioService,
      removeStudioService
    }
  }
})
