<template>
  <div class="datepicker-wrapper">
    <span class="datapicker-label">{{ label }}</span>
    <div class="datepicker">
      <datepicker :data-testid="testId" v-model="value" placeholder="Select date" inputFormat="dd/MM/yyyy" />
      <div class="date-icon"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'
import Datepicker from 'vue3-datepicker'

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    Datepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Date,
      required: false
    },
    testId: {
      type: String,
      default: 'date'
    }
  },
  setup(props) {
    const { value: formValue, handleChange, meta, errors } = useField<Date>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const value = computed({
      get(): Date {
        return formValue.value
      },
      set(newValue: Date) {
        handleChange(newValue)
      }
    })
    return { value, errors, meta }
  }
})
</script>
<style lang="sass">
.datepicker-wrapper
  display: flex
  flex-direction: column
  width: 100%
.datepicker
  position: relative
  cursor: pointer
.datapicker-label
  text-align: left
.v3dp__datepicker
  input
    width: 100%
    padding: 1rem
    padding-left: 2.5rem
    cursor: pointer
    border: solid 1px map-get($greyShades, 'greyShade20')
    border-radius: 2px
    font-size: 16px
    position: relative
    &:focus
      outline: none
.date-icon
  background-repeat: no-repeat
  background-image: url('~@/assets/icons/calendar.svg')
  background-size: contain
  background-position: center
  position: absolute
  height: 1.5rem
  width: 1.5rem
  top: 14px
  left: 5px
  pointer-events: none
</style>
