<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form :initial-values="initialValues" @submit="submit">
      <FormWrapper>
        <template #form-head>
          <h2>Invoice details</h2>
        </template>
        <Datepicker name="date" type="text" placeholderText="Date" label="Invoice date" />
        <TextInput
          :disabled="true"
          name="invoiceNumber"
          type="text"
          placeholderText="Invoice number"
          label="Invoice Number"
        />
        <div v-for="(item, index) of invoiceItemsArray" class="loop-wrapper" :key="index">
          Invoice Item
          <CustomInputModel
            :required="true"
            placeholderText="Description"
            v-model="item.description"
          ></CustomInputModel>
          <CustomInputModel
            :required="true"
            type="number"
            placeholderText="Unit cost"
            v-model="item.costPerUnit"
          ></CustomInputModel>
          <CustomInputModel
            :required="true"
            type="number"
            placeholderText="No. of units"
            v-model="item.unitsCount"
          ></CustomInputModel>
          <CustomInputModel
            :required="true"
            type="number"
            placeholderText="Tax"
            v-model="item.VatPercentage"
          ></CustomInputModel>
        </div>
        <div class="row-buttons">
          <CustomButton size="small" color="grey" iconClass="plus" @onClick="addDeliverable" label="Add deliverable" />
          <CustomButton size="small" color="grey" @onClick="removeDeliverable" label="Remove deliverable" />
        </div>
        <TextArea
          name="thankyouMessage"
          placeholderText="Thank you for your business"
          label="Thank you message"
          rows="5"
        />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Cancel" />
          <CustomButton type="submit" label="Update details" color="blue" />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import Datepicker from '@/components/atom/DatePicker.vue'
// import SelectInput from '@/components/atom/SelectInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import authApi from '@/api/Authenticated'
import { projectTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import CustomInputModel from '@/components/atom/CustomInputModel.vue'
import TextArea from '@/components/atom/TextArea.vue'
import { inputFieldFormat, currencyToInt } from '@/utils/currencyFunctions'
import moment from 'moment'

export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header,
    Datepicker,
    CustomInputModel,
    TextArea
    // SelectInput
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const invoiceId = +route.params.invoiceId
    const projectId = +route.params.projectId

    const currentValues = await authApi.getInvoiceDetails(projectId, invoiceId)
    currentValues.date = new Date(currentValues.date)
    const initialValues = ref(currentValues)
    initialValues.value.invoiceItems = initialValues.value.invoiceItems.map((item) => {
      return {
        ...item,
        costPerUnit: inputFieldFormat(item.costPerUnit)
      }
    })
    const invoiceItemsArray = ref([...initialValues.value.invoiceItems])
    const addDeliverable = () => {
      invoiceItemsArray.value.push({
        description: '',
        costPerUnit: 0,
        unitsCount: 0,
        VatPercentage: 0
      })
    }
    const removeDeliverable = () => {
      invoiceItemsArray.value.splice(invoiceItemsArray.value.length - 1, 1)
    }
    async function submit(values: projectTypes.ProjectInvoicePutBody) {
      values.date = moment(values.date).format('L')
      values.invoiceItems = invoiceItemsArray.value
      values.invoiceItems = values.invoiceItems
        .filter((element: any) => {
          return element.description !== '' && element.unitsCount !== 0 && element.costPerUnit !== 0
        })
        .map((item: any) => {
          return {
            ...item,
            unitsCount: +item.unitsCount,
            costPerUnit: currencyToInt(+item.costPerUnit),
            VatPercentage: +item.VatPercentage
          }
        })
      const res = await authApi.editInvoice(projectId, invoiceId, values)
      if (res) {
        router.push({ name: 'ProjectInvoicing', params: { id: projectId } })
      }
    }
    return { initialValues, submit, invoiceItemsArray, addDeliverable, removeDeliverable }
  }
})
</script>

<style lang="sass" scoped>
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
.row-buttons
  display: flex
  justify-content: center
  align-items: center
  & > *
    margin: 0 1rem
.loop-wrapper
  & > *
    margin: 1rem 0
</style>
