
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'SelectInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    selectLabel: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string }[],
      required: true
    },
    hintText: {
      type: String,
      required: false
    },
    testId: {
      type: String
    },
    descText: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { value: formValue, handleChange, errorMessage } = useField<string>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const value = computed({
      get(): string {
        return formValue.value ? formValue.value : props.selectLabel
      },
      set(value: string) {
        handleChange(value)
      }
    })
    return { value, handleChange, errorMessage, formValue }
  }
})
