<template>
  <div class="select-wrapper">
    <span class="label">{{ label }}</span>
    <select v-model="inputValue" @change="(event) => $emit('update:modelValue', inputValue)" :data-testid="testId">
      <option disabled :value="selectLabel">{{ selectLabel }}</option>
      <option
        v-for="item of items"
        :key="item.value"
        :value="item.value"
        :disabled="item.disabled ? item.disabled : disabled"
      >
        {{ item.label }}
      </option>
    </select>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SelectInputModal',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    selectLabel: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number, Object],
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string; disabled: boolean }[],
      required: true
    },
    testId: {
      type: String
    },
    disabled: Boolean
  },
  setup(props) {
    const inputValue = props.modelValue ? ref(props.modelValue) : props.selectLabel
    return {
      inputValue
    }
  }
})
</script>
<style lang="sass" scoped>
.select-wrapper
  display: flex
  flex-direction: column
  small
    font-size: 12px
select
  width: 100%
  padding: 1rem 0.5rem
  font-family: TT-Norms-Pro
  font-size: 16px
  background: url('~@/assets/icons/arrow-down-primary.svg')
  background-repeat: no-repeat
  background-position-x: calc( 100% - 1rem )
  background-position-y: 50%
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  background-color: $white
  border: solid 1px map-get($greyShades, 'greyShade20')
  &:active, &:focus
    outline: none
    &:disabled
      background-image: url('~@/assets/icons/arrow-down-grey.svg')
.label
  float: left
  margin: 2px 0
  font-size: 16px
</style>
