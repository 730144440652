<template>
  <div class="select-wrapper">
    <span v-if="label" class="label">{{ label }}</span>
    <small v-if="hintText">{{ hintText }}</small>
    <span v-if="descText" class="input-desc">
      {{ descText }}
    </span>
    <select v-model="value" :data-testid="testId">
      <option disabled :value="selectLabel">{{ selectLabel }}</option>
      <option v-for="item of items" :key="item.value" :value="item.value">{{ item.label }}</option>
    </select>
    <span v-if="errorMessage" class="input-error" data-testid="error">{{ errorMessage }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'SelectInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    selectLabel: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string }[],
      required: true
    },
    hintText: {
      type: String,
      required: false
    },
    testId: {
      type: String
    },
    descText: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const { value: formValue, handleChange, errorMessage } = useField<string>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const value = computed({
      get(): string {
        return formValue.value ? formValue.value : props.selectLabel
      },
      set(value: string) {
        handleChange(value)
      }
    })
    return { value, handleChange, errorMessage, formValue }
  }
})
</script>
<style lang="sass" scoped>
.select-wrapper
  display: flex
  flex-direction: column
  small
    font-size: 12px
select
  width: 100%
  padding: 1rem 0.5rem
  font-family: TT-Norms-Pro
  font-size: 16px
  background: url('~@/assets/icons/arrow-down-primary.svg')
  background-repeat: no-repeat
  background-position-x: calc( 100% - 1rem )
  background-position-y: 50%
  -moz-appearance: none
  -webkit-appearance: none
  appearance: none
  background-color: $white
  border: solid 1px map-get($greyShades, 'greyShade20')
  &:active, &:focus
    outline: none
    &:disabled
      background-image: url('~@/assets/icons/arrow-down-grey.svg')
.label
  float: left
  margin: 2px 0
  font-size: 16px
.input-desc
  color: $text-grey
  line-height: 1.3
  margin-bottom: 8px
</style>
