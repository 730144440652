<template>
  <div>
    <FormWrapper class="wrapper">
      <iframe id="invoice"></iframe>
      <template #footer>
        <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="Close" />
        <CustomButton
          color="white"
          v-if="invoiceDetails.status == 'Planned'"
          :iconVisible="false"
          :disabled="sending"
          @onClick="
            $router.push({
              name: 'EditInvoice',
              params: { projectId: +$route.params.projectId, invoiceId: +$route.params.invoiceId }
            })
          "
          label="Edit"
        />
        <CustomButton
          v-if="invoiceDetails.status == 'Planned'"
          :label="sending ? 'Sending...' : 'Save & send'"
          @click="sendInvoice"
          color="blue"
          size="large"
          :disabled="sending"
        />
        <CustomButton v-else :disabled="true" :label="invoiceDetails.status" color="blue" size="large" />
      </template>
    </FormWrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import authApi from '@/api/Authenticated'
import FormWrapper from '@/components/molecules/Form.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { projectTypes } from '@shared/'
export default defineComponent({
  components: {
    FormWrapper,
    CustomButton
  },
  async setup() {
    const route = useRoute()
    const router = useRouter()
    const sending = ref(false)
    const saving = ref(false)
    const invoiceDetails = ref<projectTypes.ProjectSingleInvoiceResponse>(
      await authApi.getInvoiceDetails(+route.params.projectId, +route.params.invoiceId)
    )
    const getPdf = async () => {
      const pdfBlob = await authApi.invoicePdf(+route.params.projectId, +route.params.invoiceId, true, undefined)
      const blob = new Blob([pdfBlob], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const iframe = document.getElementById('invoice')
      if (iframe) {
        iframe.setAttribute('src', `${url}#zoom=FitH`)
      }
    }
    getPdf()
    const sendInvoice = async () => {
      sending.value = true
      const res = await authApi.invoicePdf(+route.params.projectId, +route.params.invoiceId, false, true)
      if (res) {
        sending.value = false
        alert('Invoice has been sent to the client.')
        router.push({ name: 'ProjectInvoicing', params: { id: route.params.projectId } })
      }
    }

    return { sendInvoice, invoiceDetails, sending, saving }
  }
})
</script>

<style lang="sass" scoped>
@import '~@/sass/pdfWrapper.sass'
</style>
