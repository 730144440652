<template>
  <div class="form-group">
    <span v-if="label" class="input-label">{{ label }}</span>
    <span v-if="descText" class="input-desc">
      {{ descText }}
    </span>
    <Tooltip :tooltipText="tooltipText" :tooltipTitle="tooltipTitle" fieldType="input">
      <div class="input-field">
        <input
          :name="name"
          :value="inputValue"
          :id="name"
          :type="type"
          v-on="validationListeners"
          :placeholder="placeholderText"
          :disabled="disabled"
          :initialValues="21"
          :data-testid="testId"
        />
        <label class="label" for="name" v-if="inputValue">
          {{ placeholderText }}
          <div :class="{ verified: meta.valid }"></div>
        </label>
        <span v-if="errorMessage" class="input-error" data-testid="error">{{ errorMessage }}</span>
      </div>
    </Tooltip>
    <small class="hint-text" v-if="hint">{{ hint }}</small>
  </div>
</template>

<script>
import { useField } from 'vee-validate'
import { computed } from 'vue'
import Tooltip from '@/components/atom/Tooltip.vue'

export default {
  components: {
    Tooltip
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    hint: String,
    disabled: Boolean,
    tooltipText: {
      type: String,
      default: ''
    },
    tooltipTitle: {
      type: String,
      default: ''
    },
    testId: {
      type: String,
      default: 'input'
    },
    descText: {
      type: String,
      required: false
    }
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    // eslint-disable-next-line prettier/prettier
    const { value: inputValue, errorMessage, handleInput, handleChange, meta, initialValues } = useField(
      props.name,
      undefined,
      {
        validateOnValueUpdate: false
      },
      props.value
    )

    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: handleInput
        }
      }

      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange // only switched this
      }
    })
    return {
      validationListeners,
      errorMessage,
      inputValue,
      meta,
      initialValues
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  position: relative
  width: 100%
  display: flex
  flex-direction: column
  margin-top: 1rem
.input-field
  position: relative
input
  font-size: 1rem
  font-family: TT-Norms-Pro
  padding: 1rem 0.5rem
  display: block
  width: 100%
  border: solid 1px map-get($greyShades, 'greyShade20')
  border-radius: 2px
  caret-color: $primary
  color: $primary
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
  padding-right: 8rem
  text-overflow: ellipsis
  @media( max-width: $md-breakpoint)
    padding-right: 5rem
  &:focus
    outline: none
    border: solid 1px map-get($greyShades, 'greyShade70')
    &:disabled
      border: solid 1px map-get($greyShades, 'greyShade20')
    &:disabled ~ label
      color: map-get($greyShades, 'greyShade50')
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
    -moz-appearance: textfield
label
  color: $primary
  font-size: 14px
  font-family: TT-Norms-Pro
  font-weight: normal
  position: absolute
  pointer-events: none
  right: 1rem
  line-height: 1rem
  top: 20px
  display: flex
  align-items: center
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
.hint-text
  color: $primary
  font-family: TT-Norms-Pro
  font-size: 12px
.verified
  margin-left: 0.5rem
  height: 1rem
  width: 1rem
  background-image: url('~@/assets/icons/tick-green.svg')
  background-size: 90%
  background-position: center
  background-color: transparent
  background-repeat: no-repeat
.input-label
  text-align: left
  margin-bottom: 4px
.input-desc
  color: $text-grey
  line-height: 1.3
  margin-bottom: 8px
</style>
