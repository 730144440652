
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'
import Datepicker from 'vue3-datepicker'

export default defineComponent({
  emits: ['update:modelValue'],
  components: {
    Datepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Date,
      required: false
    },
    testId: {
      type: String,
      default: 'date'
    }
  },
  setup(props) {
    const { value: formValue, handleChange, meta, errors } = useField<Date>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const value = computed({
      get(): Date {
        return formValue.value
      },
      set(newValue: Date) {
        handleChange(newValue)
      }
    })
    return { value, errors, meta }
  }
})
