
import { defineComponent, ref } from 'vue'
import Header from '@/components/organisms/Header.vue'
import { Form } from 'vee-validate'
import SelectInput from '@/components/atom/SelectInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import FileInput from '@/components/atom/FileInput.vue'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRoute, useRouter } from 'vue-router'
import { documentTypes } from '@shared/*'
import commonString from '@/utils/commonStrings'
export default defineComponent({
  components: {
    Header,
    Form,
    SelectInput,
    FileInput,
    CustomButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const projectId = ref()
    projectId.value = route.params.id
    const items = [
      {
        id: 1,
        label: commonString.PO,
        value: 'PO'
      },
      {
        id: 2,
        label: commonString.SOW,
        value: 'SOW'
      }
    ]
    const initialValues: any = {
      type: route.query.type ?? '',
      'files[]': ''
    }
    const schema = yup.object().shape({
      type: yup.string().required().typeError('Document type is a required field'),
      files: yup.array().required().typeError('File is a required field')
    })
    async function submit(values: documentTypes.DocumentBodyFields) {
      const formData = new FormData()
      formData.append('documentType', values.type)
      formData.append('files', values.files[0])
      const res = await authApi.addProjectDocument(projectId.value, formData)
      if (res) {
        router.go(-1)
      }
    }
    return { items, schema, submit, initialValues }
  }
})
