<template>
  <input
    :name="name"
    :id="name"
    :type="type"
    v-on="validationListeners"
    :placeholder="placeholderText"
    :accept="accept"
    :data-testid="testId"
    @change="upload($event)"
  />
  <label class="label" for="name" v-if="inputValue">
    {{ placeholderText }}
    <div :class="{ verified: meta.valid }"></div>
  </label>
  <span v-if="errorMessage" class="input-error">{{ errorMessage }}</span>
</template>

<script>
import { useField } from 'vee-validate'
import { computed, ref } from 'vue'

export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    accept: {
      type: String
    },
    testId: {
      type: String
    },
    hint: String
  },
  setup(props, { emit }) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const { value: inputValue, errorMessage, handleInput, handleChange, meta } = useField(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const fileList = ref([])
    const upload = (e) => {
      fileList.value.push(e.target.files[0])
      emit('fileList', fileList.value)
    }

    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: handleInput
        }
      }

      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange // only switched this
      }
    })

    return {
      validationListeners,
      errorMessage,
      inputValue,
      meta,
      upload,
      fileList
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  position: relative
  width: 100%
  display: flex
  flex-direction: column
.input-field
  position: relative


label
  color: $primary
  font-size: 14px
  font-family: TT-Norms-Pro
  font-weight: normal
  position: absolute
  pointer-events: none
  right: 1rem
  line-height: 1rem
  top: 20px
  display: flex
  align-items: center
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
.hint-text
  color: $primary
  font-family: TT-Norms-Pro
  font-size: 12px
.verified
  margin-left: 0.5rem
  height: 1rem
  width: 1rem
  background-image: url('~@/assets/icons/tick-green.svg')
  background-size: 90%
  background-position: center
  background-color: transparent
  background-repeat: no-repeat
.input-label
  text-align: left
  margin-bottom: 4px
  margin-top: 1rem
input
    position: absolute
    width: 100%
    height: 100%
    z-index: 1
    top: 0
    left: 0
    cursor: pointer
    opacity: 0
</style>
