
import { defineComponent, ref } from 'vue'
import Header from '@/components/organisms/Header.vue'
import { Form } from 'vee-validate'
import SelectInput from '@/components/atom/SelectInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import FileInput from '@/components/atom/FileInput.vue'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRoute, useRouter } from 'vue-router'
import { documentTypes } from '@shared/*'
import commonString from '@/utils/commonStrings'
export default defineComponent({
  components: {
    Header,
    Form,
    SelectInput,
    FileInput,
    CustomButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const clientId = ref()
    clientId.value = route.params.id
    const items = [
      {
        id: 1,
        label: commonString.NDA,
        value: 'NDA'
      },
      {
        id: 2,
        label: commonString.MSA,
        value: 'MSA'
      }
    ]
    const initialValues: any = {
      documentType: route.query.type ?? '',
      'files[]': ''
    }
    const schema = yup.object().shape({
      documentType: yup.string().required().label('Document type'),
      files: yup.array().required().label('File')
    })
    async function submit(values: documentTypes.DocumentBodyFields & { documentType: any }) {
      const formData = new FormData()
      formData.append('documentType', values.documentType)
      formData.append('files', values.files[0])
      const res = await authApi.addClientDocument(clientId.value, formData)
      if (res) {
        router.go(-1)
      }
    }
    return { items, schema, submit, initialValues }
  }
})
