<template>
  <Header headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues">
      <div class="add-document">
        <h2>Add a new document</h2>
        <p>
          Add a new document here. Documents should be in either .pdf or .doc format and file size no more than 2mb.
        </p>
        <label>Document type</label>
        <SelectInput selectLabel="Select" :items="items" name="documentType" />
      </div>
      <div class="upload-button">
        <img src="@/assets/icons/document.svg" />
        <CustomButton label="Add a file" color="white" iconClass="plus" />
        <span>File should be .pdf or .doc format and under 2mb</span>
        <FileInput type="file" name="files" accept=".pdf, .doc, .png" />
      </div>
      <div class="action">
        <CustomButton label="Cancel" color="grey" @onClick="$router.go(-1)" :iconVisible="false" />
        <CustomButton type="submit" label="Upload document" color="blue" />
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Header from '@/components/organisms/Header.vue'
import { Form } from 'vee-validate'
import SelectInput from '@/components/atom/SelectInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import FileInput from '@/components/atom/FileInput.vue'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRoute, useRouter } from 'vue-router'
import { documentTypes } from '@shared/*'
import commonString from '@/utils/commonStrings'
export default defineComponent({
  components: {
    Header,
    Form,
    SelectInput,
    FileInput,
    CustomButton
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const subcontractorId = ref()
    subcontractorId.value = route.params.id
    const items = [
      {
        id: 1,
        label: commonString.NDA,
        value: 'NDA'
      },
      {
        id: 2,
        label: commonString.MSA,
        value: 'MSA'
      },
      {
        id: 3,
        label: commonString.SOW,
        value: 'SOW'
      }
    ]

    const initialValues: any = {
      documentType: route.query.type ?? '',
      'files[]': ''
    }

    const schema = yup.object().shape({
      documentType: yup.string().required(),
      files: yup.array().required()
    })
    async function submit(values: documentTypes.DocumentBodyFields & { documentType: any }) {
      const formData = new FormData()
      formData.append('documentType', values.documentType)
      formData.append('files', values.files[0])
      const res = await authApi.addSubcontractorDocument(subcontractorId.value, formData)
      if (res) {
        router.go(-1)
      }
    }
    return { items, schema, submit, initialValues }
  }
})
</script>
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
.action
  background: $white
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 0.8rem
  position: fixed
  bottom: 0
  left: 0
  z-index: 2
  box-shadow: 0px -8px 36px rgba(14, 30, 54, 0.05)
  button
    max-width: 15rem
    width: max-content
    &:not(:last-child)
      margin-right: 1rem
</style>
