
import { defineComponent, ref } from 'vue'
import authApi from '@/api/Authenticated'
import FormWrapper from '@/components/molecules/Form.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { projectTypes } from '@shared/'
export default defineComponent({
  components: {
    FormWrapper,
    CustomButton
  },
  async setup() {
    const route = useRoute()
    const router = useRouter()
    const sending = ref(false)
    const saving = ref(false)
    const invoiceDetails = ref<projectTypes.ProjectSingleInvoiceResponse>(
      await authApi.getInvoiceDetails(+route.params.projectId, +route.params.invoiceId)
    )
    const getPdf = async () => {
      const pdfBlob = await authApi.invoicePdf(+route.params.projectId, +route.params.invoiceId, true, undefined)
      const blob = new Blob([pdfBlob], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const iframe = document.getElementById('invoice')
      if (iframe) {
        iframe.setAttribute('src', `${url}#zoom=FitH`)
      }
    }
    getPdf()
    const sendInvoice = async () => {
      sending.value = true
      const res = await authApi.invoicePdf(+route.params.projectId, +route.params.invoiceId, false, true)
      if (res) {
        sending.value = false
        alert('Invoice has been sent to the client.')
        router.push({ name: 'ProjectInvoicing', params: { id: route.params.projectId } })
      }
    }

    return { sendInvoice, invoiceDetails, sending, saving }
  }
})
