
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SelectInputModal',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    selectLabel: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number, Object],
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string; disabled: boolean }[],
      required: true
    },
    testId: {
      type: String
    },
    disabled: Boolean
  },
  setup(props) {
    const inputValue = props.modelValue ? ref(props.modelValue) : props.selectLabel
    return {
      inputValue
    }
  }
})
