const VALID_EMAIL = 'Please enter valid email'
const CARRIER = 'Carrier'
const COLLATION = 'Collation'
const CASH_AND_CARRY = 'Cash and carry'
const NDA = 'Non-disclosure agreement'
const MSA = 'Master services agreement'
const SOW = 'Statement of work'
const PO = 'Purchase order'
const SI = 'Supplier invoice'
const FixedPrice = 'Fixed price'
const BillPerHour = 'Bill per hour'

export default {
  VALID_EMAIL: VALID_EMAIL,
  CARRIER: CARRIER,
  COLLATION: COLLATION,
  CASH_AND_CARRY: CASH_AND_CARRY,
  NDA: NDA,
  MSA: MSA,
  SOW: SOW,
  PO: PO,
  SI: SI,
  FixedPrice: FixedPrice,
  BillPerHour: BillPerHour,
  ADMIN: 'Admin',
  MEMBER: 'Member',
  PRO_MAN: 'Project manager',
  ACC_MAN: 'Account manager'
}
