<template>
  <div class="form-group">
    <span v-if="label" class="input-label">{{ label }}</span>
    <div class="input-field">
      <input
        :name="name"
        :value="inputValue"
        :id="name"
        :data-testid="testId"
        :type="type"
        v-on="validationListeners"
        :placeholder="placeholderText"
        :disabled="disabled"
        :inputType="inputType"
      />
      <span v-if="errorMessage" class="input-error">{{ errorMessage }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useField } from 'vee-validate'

declare const google: any
export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    id: {
      type: String
    },
    inputType: {
      type: String
    },
    testId: {
      type: String
    },
    hint: String,
    disabled: Boolean
  },
  setup(props) {
    const propsData = props
    const address = ref()
    // const inputValue = ref()
    const latitude = ref()
    const longitude = ref()

    // eslint-disable-next-line prettier/prettier
    const {
      value: inputValue,
      errorMessage,
      handleInput,
      handleChange
    } = useField(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: handleInput
        }
      }

      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange // only switched this
      }
    })
    async function getStreetAddressFrom(lat: number, long: number) {
      try {
        const res = await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            long +
            '&key=AIzaSyBdsjb_RUMEkFt5eBE1iyaHrY-FVEiyMSI'
        )
        const data = await res.json()
        if (data.error_message) {
        } else {
          address.value = data.results ? data.results[0].formatted_address : ''
          inputValue.value = address.value ? address.value : null
        }
      } catch (error) {
        console.log(error)
      }
    }
    async function geolocation() {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById(propsData.id || ''))
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace().formatted_address
        inputValue.value = place
      })
      if (propsData.inputType !== 'Edit') {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            latitude.value = position.coords.latitude
            longitude.value = position.coords.longitude
            await getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
          },
          (error) => {
            console.log(error.message)
          }
        )
      }
    }

    onMounted(geolocation)

    return {
      inputValue,
      latitude,
      longitude,
      validationListeners,
      address,
      errorMessage
    }
  }
})
</script>
<style lang="sass" scoped>
.form-group
    position: relative
    width: 100%
    display: flex
    flex-direction: column
.input-field
    position: relative
input
    font-size: 1rem
    font-family: TT-Norms-Pro
    padding: 1rem 0.5rem
    display: block
    width: 100%
    border: solid 1px map-get($greyShades, 'greyShade20')
    border-radius: 2px
    caret-color: $primary
    color: $primary
    transition: 0.2s ease all
    -moz-transition: 0.2s ease all
    -webkit-transition: 0.2s ease all
    text-overflow: ellipsis
    &:focus
        outline: none
        border: solid 1px map-get($greyShades, 'greyShade70')
        &:disabled
            border: solid 1px map-get($greyShades, 'greyShade20')
        &:disabled ~ label
            color: map-get($greyShades, 'greyShade50')

label
    color: $primary
    font-size: 14px
    font-family: TT-Norms-Pro
    font-weight: normal
    position: absolute
    pointer-events: none
    right: 1rem
    line-height: 1rem
    top: 20px
    display: flex
    align-items: center
    transition: 0.2s ease all
    -moz-transition: 0.2s ease all
    -webkit-transition: 0.2s ease all
.hint-text
    color: $primary
    font-family: TT-Norms-Pro
    font-size: 12px
.input-label
    text-align: left
    margin-bottom: 4px
    margin-top: 1rem
</style>
