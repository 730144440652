
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useField } from 'vee-validate'

declare const google: any
export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    id: {
      type: String
    },
    inputType: {
      type: String
    },
    testId: {
      type: String
    },
    hint: String,
    disabled: Boolean
  },
  setup(props) {
    const propsData = props
    const address = ref()
    // const inputValue = ref()
    const latitude = ref()
    const longitude = ref()

    // eslint-disable-next-line prettier/prettier
    const {
      value: inputValue,
      errorMessage,
      handleInput,
      handleChange
    } = useField(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const validationListeners = computed(() => {
      // If the field is valid or have not been validated yet
      // lazy
      if (!errorMessage.value) {
        return {
          blur: handleChange,
          change: handleChange,
          input: handleInput
        }
      }

      // Aggressive
      return {
        blur: handleChange,
        change: handleChange,
        input: handleChange // only switched this
      }
    })
    async function getStreetAddressFrom(lat: number, long: number) {
      try {
        const res = await fetch(
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            long +
            '&key=AIzaSyBdsjb_RUMEkFt5eBE1iyaHrY-FVEiyMSI'
        )
        const data = await res.json()
        if (data.error_message) {
        } else {
          address.value = data.results ? data.results[0].formatted_address : ''
          inputValue.value = address.value ? address.value : null
        }
      } catch (error) {
        console.log(error)
      }
    }
    async function geolocation() {
      const autocomplete = new google.maps.places.Autocomplete(document.getElementById(propsData.id || ''))
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace().formatted_address
        inputValue.value = place
      })
      if (propsData.inputType !== 'Edit') {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            latitude.value = position.coords.latitude
            longitude.value = position.coords.longitude
            await getStreetAddressFrom(position.coords.latitude, position.coords.longitude)
          },
          (error) => {
            console.log(error.message)
          }
        )
      }
    }

    onMounted(geolocation)

    return {
      inputValue,
      latitude,
      longitude,
      validationListeners,
      address,
      errorMessage
    }
  }
})
